import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(15),
        marginBottom: theme.spacing(10),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "white",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    title: {
        fontSize: 18,
        color: "white",
        backgroundColor: "#009999",
        marginTop: 0,
        padding: 20,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(2),
        padding: "20px 50px 20px 50px",
    },
    formControl: {
        margin: theme.spacing(0),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Accessibility(props: any) {
    const classes = useStyles();

    const [propertyAccess, setPropertyAccess] = useState<any>(null);
    const [parkingAvailability, setParkingAvailability] = useState<any>(null);
    const [pets, setPets] = useState<any>(null);
    const [comments, setComments] = useState<any>("");

    useEffect(() => {
        document.title = "Aberdeen Cleaning - Quote Request";
    }, []);

    const handleChangePropertyAccess = (
        event: React.ChangeEvent<{ value: any }>
    ) => {
        setPropertyAccess(event.target.value);
    };

    const handleChangeParkingAvailability = (
        event: React.ChangeEvent<{ value: any }>
    ) => {
        setParkingAvailability(event.target.value);
    };

    const handleChangePets = (event: React.ChangeEvent<{ value: any }>) => {
        setPets(event.target.value);
    };

    const proceedToNextStep = () => {
        props.setInputData({
            propertyAccess: propertyAccess,
            parkingAvailability: parkingAvailability,
            pets: pets,
            comments: comments,
        });
        props.nextStep();
    };

    const isFormValid = () => {
        return propertyAccess && parkingAvailability && pets;
    };

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.formControl}
                        required
                    >
                        <InputLabel id="demo-simple-select-outlined-label">
                            How will your cleaner access the property?
                        </InputLabel>
                        <Select
                            style={{ justifyContent: "flex-start" }}
                            labelId="demo-simple-select-outlined-label"
                            value={propertyAccess}
                            onChange={handleChangePropertyAccess}
                            label="How will your cleaner access the property?"
                        >
                            <MenuItem value={"  I will provide keys"}>
                                I will provide keys
                            </MenuItem>
                            <MenuItem value={"I will provide keys"}>
                                I will let cleaner in
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.formControl}
                        required
                    >
                        <InputLabel id="demo-simple-select-outlined-label">
                            Parking availability
                        </InputLabel>
                        <Select
                            style={{ justifyContent: "flex-start" }}
                            labelId="demo-simple-select-outlined-label"
                            value={parkingAvailability}
                            onChange={handleChangeParkingAvailability}
                            label="Parking availability"
                        >
                            <MenuItem value={" Parking is available"}>
                                Parking is available
                            </MenuItem>
                            <MenuItem value={" Permit parking"}>
                                Permit parking
                            </MenuItem>
                            <MenuItem value={"No parking available"}>
                                No parking available
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.formControl}
                        required
                    >
                        <InputLabel id="demo-simple-select-outlined-label">
                            Do you have any pets?
                        </InputLabel>
                        <Select
                            style={{ justifyContent: "flex-start" }}
                            labelId="demo-simple-select-outlined-label"
                            value={pets}
                            onChange={handleChangePets}
                            label="Do you have any pets?"
                        >
                            <MenuItem value={"Yes"}>Yes</MenuItem>
                            <MenuItem value={"No"}>No</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel
                        style={{
                            marginBottom: 15,
                            textAlign: "start",
                        }}
                    >
                        &nbsp;&nbsp;&nbsp;Further comments
                    </InputLabel>
                    <TextareaAutosize
                        minRows={8}
                        placeholder=" "
                        style={{ width: "100%" }}
                        onChange={(e) => setComments(e.target.value)}
                    />
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Button
                            style={{
                                backgroundColor: "#009999",
                                color: "white",
                            }}
                            onClick={props.previousStep}
                            fullWidth
                            variant="contained"
                            className={classes.submit}
                        >
                            Previous Step
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            style={{
                                backgroundColor: "#009999",
                                color: "white",
                            }}
                            onClick={proceedToNextStep}
                            disabled={!isFormValid()}
                            fullWidth
                            variant="contained"
                            className={classes.submit}
                        >
                            Continue
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(15),
        marginBottom: theme.spacing(10),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "white",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    title: {
        fontSize: 18,
        color: "white",
        backgroundColor: "#009999",
        marginTop: 0,
        padding: 20,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(2),
        padding: "20px 50px 20px 50px",
    },
    formControl: {
        margin: theme.spacing(0),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function PersonalDetails(props: any) {
    const classes = useStyles();

    const [firstName, setFirstName] = useState<any>(null);
    const [lastName, setLastName] = useState<any>(null);
    const [email, setEmail] = useState<any>(null);
    const [phone, setPhone] = useState<any>(null);
    const [address, setAddress] = useState<any>(null);
    const [postcode, setPostcode] = useState<any>(null);

    useEffect(() => {
        document.title = "Aberdeen Cleaning - Quote Request";
    }, []);

    const proceedToNextStep = () => {
        props.setInputData({
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            address: address,
            postcode: postcode,
        });
        props.nextStep();
    };

    const isFormValid = () => {
        return (
            firstName &&
            firstName.length > 0 &&
            firstName &&
            firstName.length > 0 &&
            lastName &&
            lastName.length > 0 &&
            email &&
            email.length > 0 &&
            phone &&
            phone.length > 0 &&
            address &&
            address.length > 0 &&
            postcode &&
            postcode.length > 0
        );
    };

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="firstName"
                        autoComplete="fname"
                        variant="outlined"
                        required
                        fullWidth
                        label="First name"
                        autoFocus
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="lastName"
                        variant="outlined"
                        required
                        fullWidth
                        label="Last name"
                        autoComplete="lname"
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="email"
                        variant="outlined"
                        required
                        fullWidth
                        label="Email"
                        autoComplete="email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="phone"
                        variant="outlined"
                        required
                        fullWidth
                        label="Phone"
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="address"
                        variant="outlined"
                        required
                        fullWidth
                        label="Address"
                        onChange={(e) => setAddress(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="postcode"
                        variant="outlined"
                        required
                        fullWidth
                        label="Postcode"
                        onChange={(e) => setPostcode(e.target.value)}
                    />
                </Grid>
            </Grid>
            <Button
                style={{
                    backgroundColor: "#009999",
                    color: "white",
                }}
                onClick={proceedToNextStep}
                disabled={!isFormValid()}
                fullWidth
                variant="contained"
                className={classes.submit}
            >
                Continue
            </Button>
        </React.Fragment>
    );
}

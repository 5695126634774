import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Button } from "@material-ui/core";
import { Zoom, Fade, Slide } from "@material-ui/core";

export default function ImageSlider(props: any) {
    var items = [
        {
            description: "Cleaning services",
            image: "1.jpg",
        },
        {
            description: "Professional Cleaners",
            image: "2.jpg",
        },
        {
            description: "Competitive rates without hidden charges",
            image: "3.jpg",
        },
        {
            description: "Quality and reliable Service",
            image: "4.jpg",
        },
        {
            description: "Proving our commitment to your satisfaction",
            image: "5.jpg",
        },
    ];

    return (
        <Carousel interval={5000} stopAutoPlayOnHover={false}>
            {items.map((item, i) => (
                <Item key={i} item={item} />
            ))}
        </Carousel>
    );
}

function Item(props: any) {
    return (
        <React.Fragment>
            <img
                alt="background"
                src={`${process.env.PUBLIC_URL}/assets/images/${props.item.image}`}
                style={{ width: "100%", height: 600, marginTop: 5 }}
            ></img>
            <div
                style={{
                    flex: 1,
                    position: "absolute",
                    left: 0,
                    top: 0,
                    marginTop: 50,
                    alignItems: "center",
                    color: "white",
                    fontSize: 45,
                    fontWeight: "bold",
                    width: "100%",
                    textShadow: "2px 2px 2px #000000",
                    flexDirection: "row",
                }}
            >
                <Fade in={true}>
                    <div style={{ marginTop: 200 }}>
                        <p>{props.item.description}</p>

                        <Button
                            href="/online-booking"
                            variant="outlined"
                            style={{
                                color: "white",
                                padding: "10px 25px 10px 25px",
                                borderWidth: 2,
                                borderColor: "white",
                                marginTop: -60,
                                // backgroundColor: "#0076BE",
                            }}
                        >
                            BOOK ONLINE
                        </Button>
                    </div>
                </Fade>
            </div>
        </React.Fragment>
    );
}

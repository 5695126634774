import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { Paper } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Accordion = withStyles({
	root: {
		border: "1px solid rgba(0, 0, 0, .125)",
		boxShadow: "none",
		"&:not(:last-child)": {
			borderBottom: 0,
		},
		"&:before": {
			display: "none",
		},
		"&$expanded": {
			margin: "auto",
		},
	},
	expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
	root: {
		backgroundColor: "rgba(0, 0, 0, .03)",
		borderBottom: "1px solid rgba(0, 0, 0, .125)",
		marginBottom: -1,
		minHeight: 56,
		"&$expanded": {
			minHeight: 56,
		},
	},
	content: {
		"&$expanded": {
			margin: "12px 0",
		},
	},
	expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: 18,
		color: "white",
		backgroundColor: "#009999",
		margin: 0,
		padding: "15px 30px 15px 30px",
		textAlign: "left",
	},
	summary: {
		paddingLeft: 30,
		backgroundColor: "#e6e6e6",
	},
	details: {
		paddingLeft: 30,
	},
	expandIcon: {
		color: "grey",
		position: "absolute",
		left: 250,
	},
}));

export default function Pricing() {
	const classes = useStyles();
	const [expanded, setExpanded] = React.useState(true);

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	return (
		<div style={{ padding: 50 }}>
			<Paper>
				<div className={classes.title}>
					All our cleaning services are listed below with pricing
					details
				</div>
				<Accordion
					square
					expanded={true}
					onChange={handleChange("panel1")}
				>
					<AccordionSummary
						className={classes.summary}
						aria-controls="panel1d-content"
						id="panel1d-header"
					>
						<Typography>Regular Domestic Cleaning</Typography>
						<ExpandMoreIcon className={classes.expandIcon} />
					</AccordionSummary>
					<AccordionDetails className={classes.details}>
						<Typography>
							<div style={{ textAlign: "left", fontSize: 15 }}>
								<span>We charge from £15 per hour</span>
							</div>
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					square
					expanded={true}
					onChange={handleChange("panel2")}
				>
					<AccordionSummary
						className={classes.summary}
						aria-controls="panel2d-content"
						id="panel2d-header"
					>
						<Typography>Deep Cleaning</Typography>
						<ExpandMoreIcon className={classes.expandIcon} />
					</AccordionSummary>
					<AccordionDetails className={classes.details}>
						<Typography>
							<div style={{ textAlign: "left", fontSize: 15 }}>
								<span>We charge from £20 per hour</span>
								<br />
							</div>
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					square
					expanded={true}
					onChange={handleChange("panel3")}
				>
					<AccordionSummary
						className={classes.summary}
						aria-controls="panel3d-content"
						id="panel3d-header"
					>
						<Typography>Housekeeping</Typography>
						<ExpandMoreIcon className={classes.expandIcon} />
					</AccordionSummary>
					<AccordionDetails className={classes.details}>
						<Typography>
							<div style={{ textAlign: "left", fontSize: 15 }}>
								<span>We charge from £15 per hour</span>
								<br />
							</div>
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					square
					expanded={true}
					onChange={handleChange("panel4")}
				>
					<AccordionSummary
						className={classes.summary}
						aria-controls="panel4d-content"
						id="panel4d-header"
					>
						<Typography>Commercial Cleaning</Typography>
						<ExpandMoreIcon className={classes.expandIcon} />
					</AccordionSummary>
					<AccordionDetails className={classes.details}>
						<Typography>
							<div style={{ textAlign: "left", fontSize: 15 }}>
								<span>We charge from £20 per hour</span>
								<br />
							</div>
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					square
					expanded={true}
					onChange={handleChange("panel5")}
				>
					<AccordionSummary
						className={classes.summary}
						aria-controls="panel5d-content"
						id="panel5d-header"
					>
						<Typography>Pre Tenancy Cleaning</Typography>
						<ExpandMoreIcon className={classes.expandIcon} />
					</AccordionSummary>
					<AccordionDetails className={classes.details}>
						<Typography>
							<div style={{ textAlign: "left", fontSize: 15 }}>
								<span>We charge from £95</span>
								<br />
							</div>
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					square
					expanded={true}
					onChange={handleChange("panel6")}
				>
					<AccordionSummary
						className={classes.summary}
						aria-controls="panel6d-content"
						id="panel6d-header"
					>
						<Typography>End of Tenancy Cleaning</Typography>
						<ExpandMoreIcon className={classes.expandIcon} />
					</AccordionSummary>
					<AccordionDetails className={classes.details}>
						<Typography>
							<div style={{ textAlign: "left", fontSize: 15 }}>
								<span>We charge from £195</span>
								<br />
							</div>
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					square
					expanded={true}
					onChange={handleChange("panel7")}
				>
					<AccordionSummary
						className={classes.summary}
						aria-controls="panel7d-content"
						id="panel7d-header"
					>
						<Typography>Ironing</Typography>
						<ExpandMoreIcon className={classes.expandIcon} />
					</AccordionSummary>
					<AccordionDetails className={classes.details}>
						<Typography>
							<div style={{ textAlign: "left", fontSize: 15 }}>
								<span>We charge from £15 per hour</span>
								<br />
							</div>
						</Typography>
					</AccordionDetails>
				</Accordion>
				{/* <Accordion
					square
					expanded={expanded === "panel8"}
					onChange={handleChange("panel8")}
				>
					<AccordionSummary
						className={classes.summary}
						aria-controls="panel8d-content"
						id="panel8d-header"
					>
						<Typography>Organising & Decorating</Typography>
						<ExpandMoreIcon className={classes.expandIcon} />
					</AccordionSummary>
					<AccordionDetails className={classes.details}>
						<Typography>
							<div style={{ textAlign: "left", fontSize: 15 }}>
								<span>£16.50 – per hour weekly</span>
								<br />

								<span>£17.50 – per hour fortnightly</span>
								<br />
								<br />
								<span>Description...</span>
							</div>
						</Typography>
					</AccordionDetails>
				</Accordion> */}
			</Paper>
		</div>
	);
}

import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Paper } from "@material-ui/core";
import { API } from "aws-amplify";
import apiConfig from "../config/api-config";
import Notification from "./Notification";
import Footer from "./Footer";
import StepWizard from "react-step-wizard";
import PersonalDetails from "../components/PersonalDetails";
import Accessibility from "./Accessibility";
import Requirements from "../components/Requirements";
import BookingSubmission from "../components/BookingSubmission";
import FormProgressBar from "./ProgressBar";

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: 400,
    },
    paper: {
        marginTop: theme.spacing(15),
        marginBottom: theme.spacing(10),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "white",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    title: {
        fontSize: 18,
        color: "white",
        backgroundColor: "#009999",
        marginTop: 0,
        padding: 20,
        width: "100%",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(2),
        padding: "20px 50px 20px 50px",
    },
    formControl: {
        margin: theme.spacing(0),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    progressBarContainer: {
        width: "80%",
        color: "grey",
        marginTop: 20,
        marginBottom: 15,
    },
}));

export default function OnlineBooking() {
    const classes = useStyles();

    const [firstName, setFirstName] = useState<any>(null);
    const [lastName, setLastName] = useState<any>(null);
    const [email, setEmail] = useState<any>(null);
    const [phone, setPhone] = useState<any>(null);
    const [address, setAddress] = useState<any>(null);
    const [postcode, setPostcode] = useState<any>(null);

    const [serviceType, setServiceType] = useState<any>(null);
    const [frequency, setFrequency] = useState<any>(null);
    const [cleaningDays, setCleaningDays] = useState<any>(null);
    const [cleaningTimes, setCleaningTimes] = useState<any>(null);
    const [startDate, setStartDate] = useState<any>(null);
    const [startTime, setStartTime] = useState<any>(null);
    const [requirementDesc, setrequirementDesc] = useState<any>("");

    const [propertyAccess, setPropertyAccess] = useState<any>(null);
    const [parkingAvailability, setParkingAvailability] = useState<any>(null);
    const [pets, setPets] = useState<any>(null);
    const [comments, setComments] = useState<any>("");

    const [percentage, setPercentage] = useState<number>(0);
    const [message, setMessage] = useState<any>(null);
    const [messageType, setMessageType] = useState<any>(null);
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        document.title = "Aberdeen Cleaning - Quote Request";
    }, []);

    const closeNotification = () => {
        setShowMessage(false);
    };

    const setPersonalDetails = (data) => {
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setEmail(data.email);
        setPhone(data.phone);
        setAddress(data.address);
        setPostcode(data.postcode);
        setPercentage(25);
    };

    const setRequirements = (data) => {
        setServiceType(data.serviceType);
        setFrequency(data.frequency);
        setCleaningDays(data.cleaningDays);
        setCleaningTimes(data.cleaningTimes);
        setStartDate(data.startDate);
        setStartTime(data.startTime);
        setrequirementDesc(data.requirementDesc);
        setPercentage(50);
    };

    const setAccessibility = (data) => {
        setPropertyAccess(data.propertyAccess);
        setParkingAvailability(data.parkingAvailability);
        setPets(data.pets);
        setComments(data.comments);
        setPercentage(75);
    };

    const submitBooking = async (event: any) => {
        try {
            const data = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone: phone,
                address: address,
                postcode: postcode,
                serviceType: serviceType,
                frequency: frequency,
                cleaningDays: cleaningDays,
                cleaningTimes: cleaningTimes,
                startDate: startDate,
                startTime: startTime,
                requirementDesc: requirementDesc,
                propertyAccess: propertyAccess,
                parkingAvailability: parkingAvailability,
                pets: pets,
                comments: comments,
            };
     
            const res = await API.post(
                apiConfig.name,
                `/${apiConfig.env}/quote-request`,
                {
                    body: data,
                }
            );
            await setMessage("Request submitted successfully!");
            await setMessageType("success");
            await setShowMessage(true);
            setPercentage(100);
            setTimeout(() => {
                window.location.replace("/");
            }, 3000);
        } catch (error) {
            console.log(error);
            await setMessage("An error occurred. Please try again!");
            await setMessageType("error");
            await setShowMessage(true);
        }
    };

    return (
        <React.Fragment>
            <Container
                className={classes.container}
                component="main"
                maxWidth="sm"
            >
                <CssBaseline />
                <Paper className={classes.paper}>
                    <p className={classes.title}>
                        Instantly book cleaning services online
                    </p>
                    <div className={classes.progressBarContainer}>
                        <FormProgressBar
                            percentage={percentage}
                        ></FormProgressBar>
                    </div>
                    <form className={classes.form} noValidate>
                        <StepWizard>
                            <PersonalDetails
                                setInputData={setPersonalDetails}
                            ></PersonalDetails>
                            <Requirements
                                setInputData={setRequirements}
                            ></Requirements>
                            <Accessibility
                                setInputData={setAccessibility}
                            ></Accessibility>
                            <BookingSubmission
                                data={{
                                    firstName: firstName,
                                    lastName: lastName,
                                    email: email,
                                    phone: phone,
                                    address: address,
                                    postcode: postcode,
                                    serviceType: serviceType,
                                    frequency: frequency,
                                    cleaningDays: cleaningDays,
                                    cleaningTimes: cleaningTimes,
                                    startDate: startDate,
                                    startTime: startTime,
                                    requirementDesc: requirementDesc,
                                    propertyAccess: propertyAccess,
                                    parkingAvailability: parkingAvailability,
                                    pets: pets,
                                    comments: comments,
                                }}
                                submitBooking={submitBooking}
                            ></BookingSubmission>
                        </StepWizard>
                    </form>
                </Paper>
                {showMessage && (
                    <Notification
                        open={showMessage}
                        message={message}
                        type={messageType}
                        closeNotification={closeNotification}
                    ></Notification>
                )}
            </Container>
            <Footer></Footer>
        </React.Fragment>
    );
}

import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(15),
        marginBottom: theme.spacing(10),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "white",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    title: {
        fontSize: 18,
        color: "white",
        backgroundColor: "#009999",
        marginTop: 0,
        padding: 20,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(2),
        padding: "20px 50px 20px 50px",
    },
    formControl: {
        margin: theme.spacing(0),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function PersonalDetails(props: any) {
    const classes = useStyles();

    const [serviceType, setServiceType] = useState<any>(null);
    const [frequency, setFrequency] = useState<any>(null);
    const [cleaningDays, setCleaningDays] = useState<any>(null);
    const [cleaningTimes, setCleaningTimes] = useState<any>(null);
    const [startDate, setStartDate] = useState<any>(null);
    const [startTime, setStartTime] = useState<any>(null);
    const [requirementDesc, setrequirementDesc] = useState<any>("");

    useEffect(() => {
        document.title = "Aberdeen Cleaning - Quote Request";
    }, []);

    const handleChangeServiceType = (
        event: React.ChangeEvent<{ value: any }>
    ) => {
        setServiceType(event.target.value);
    };

    const handleChangeFrequency = (
        event: React.ChangeEvent<{ value: any }>
    ) => {
        setFrequency(event.target.value);
    };

    const handleChangeCleaningDays = (
        event: React.ChangeEvent<{ value: any }>
    ) => {
        setCleaningDays(event.target.value);
    };

    const handleChangeCleaningTimes = (
        event: React.ChangeEvent<{ value: any }>
    ) => {
        setCleaningTimes(event.target.value);
    };

    const handleDateChange = (date: Date | null) => {
        setStartDate(date);
    };

    const handleTimeChange = (date: Date | null) => {
        setStartTime(date);
    };

    const proceedToNextStep = () => {
        props.setInputData({
            serviceType: serviceType,
            frequency: frequency,
            cleaningDays: cleaningDays,
            cleaningTimes: cleaningTimes,
            startDate: startDate,
            startTime: startTime,
            requirementDesc: requirementDesc,
        });
        props.nextStep();
    };

    const isFormValid = () => {
        return (
            serviceType &&
            frequency &&
            cleaningDays &&
            cleaningTimes &&
            startDate &&
            startTime
        );
    };

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.formControl}
                        required
                    >
                        <InputLabel id="demo-simple-select-outlined-label">
                            Type of service
                        </InputLabel>
                        <Select
                            style={{ justifyContent: "flex-start" }}
                            labelId="demo-simple-select-outlined-label"
                            value={serviceType}
                            onChange={handleChangeServiceType}
                            label="Type of service"
                        >
                            <MenuItem value={"Regular Domestic Cleaning"}>
                                Regular Domestic Cleaning
                            </MenuItem>
                            <MenuItem value={"  Office Cleaning"}>
                                Office Cleaning
                            </MenuItem>
                            <MenuItem value={"End-of-tenancy cleaning"}>
                                End of Tenancy Cleaning
                            </MenuItem>
                            <MenuItem value={"Deep Cleaning"}>
                                Deep Cleaning
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.formControl}
                        required
                    >
                        <InputLabel id="demo-simple-select-outlined-label">
                            How often do you require our service?
                        </InputLabel>
                        <Select
                            style={{ justifyContent: "flex-start" }}
                            labelId="demo-simple-select-outlined-label"
                            value={frequency}
                            onChange={handleChangeFrequency}
                            label="How often do you require our service?"
                        >
                            <MenuItem value={"Daily"}>Daily</MenuItem>
                            <MenuItem value={"Weekly"}>Weekly</MenuItem>
                            <MenuItem value={"Fortnightly"}>
                                Fortnightly
                            </MenuItem>
                            <MenuItem value={"One-off"}>One-off</MenuItem>
                            <MenuItem value={"Other"}>Other</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.formControl}
                        required
                    >
                        <InputLabel id="demo-simple-select-outlined-label">
                            Your preferred day for cleaning
                        </InputLabel>
                        <Select
                            style={{ justifyContent: "flex-start" }}
                            labelId="demo-simple-select-outlined-label"
                            value={cleaningDays}
                            onChange={handleChangeCleaningDays}
                            label="How will your cleaner access the property?"
                        >
                            <MenuItem value={"Monday"}>Monday</MenuItem>
                            <MenuItem value={"Tuesday"}>Tuesday</MenuItem>
                            <MenuItem value={"Wednesday"}>Wednesday</MenuItem>
                            <MenuItem value={"Thursday"}>Thursday</MenuItem>
                            <MenuItem value={"Friday"}>Friday</MenuItem>
                            <MenuItem value={"Saturday"}>Saturday</MenuItem>
                            <MenuItem value={"Sunday"}>Sunday</MenuItem>
                            <MenuItem value={"Flexible"}>Flexible</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl
                        variant="outlined"
                        fullWidth
                        className={classes.formControl}
                        required
                    >
                        <InputLabel id="demo-simple-select-outlined-label">
                            Your preferred time for cleaning
                        </InputLabel>
                        <Select
                            style={{ justifyContent: "flex-start" }}
                            labelId="demo-simple-select-outlined-label"
                            value={cleaningTimes}
                            onChange={handleChangeCleaningTimes}
                            label="Your preferred time"
                        >
                            <MenuItem value={"Morning"}>Morning</MenuItem>
                            <MenuItem value={"Afternoon"}>Afternoon</MenuItem>
                            <MenuItem value={"Evening"}>Evening</MenuItem>
                            <MenuItem value={"Flexible"}>Flexible</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} style={{marginTop: -10}}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={6}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Service Start Date"
                                    format="MM/dd/yyyy"
                                    value={startDate}
                                    minDate={new Date()}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    label="Select Time"
                                    value={startTime}
                                    onChange={handleTimeChange}
                                    KeyboardButtonProps={{
                                        "aria-label": "change time",
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12}>
                    <InputLabel
                        style={{
                            marginBottom: 15,
                            textAlign: "start",
                        }}
                    >
                        &nbsp;&nbsp;&nbsp;Your requirements in detail
                    </InputLabel>
                    <TextareaAutosize
                        minRows={8}
                        placeholder=" "
                        style={{ width: "100%" }}
                        onChange={(e) => setrequirementDesc(e.target.value)}
                    />
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Button
                            style={{
                                backgroundColor: "#009999",
                                color: "white",
                            }}
                            onClick={props.previousStep}
                            fullWidth
                            variant="contained"
                            className={classes.submit}
                        >
                            Previous Step
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            style={{
                                backgroundColor: "#009999",
                                color: "white",
                            }}
                            onClick={proceedToNextStep}
                            disabled={!isFormValid()}
                            fullWidth
                            variant="contained"
                            className={classes.submit}
                        >
                            Continue
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

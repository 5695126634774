import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(15),
        marginBottom: theme.spacing(10),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "white",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    title: {
        fontSize: 18,
        color: "white",
        backgroundColor: "#009999",
        marginTop: 0,
        padding: 20,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(2),
        padding: "20px 50px 20px 50px",
    },
    formControl: {
        margin: theme.spacing(0),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    section: {
        marginBottom: 25,
        textAlign: "start",
        padding: 5,
    },
    item: {
        width: 100,
        marginBottom: 15,
    },
    itemTitle: {
        height: 40,
        justifyContent: "center",
        backgroundColor: "#f2f2f2",
        borderRadius: 3,
        width: "100%",
        padding: 10,
    },
    itemContent: {
        padding: 10,
    },
}));

export default function BookingSubmission(props: any) {
    const classes = useStyles();

    useEffect(() => {
        document.title = "Aberdeen Cleaning - Quote Request";
        console.log(props.data);
    }, []);

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className={classes.section}>
                        <div className={classes.itemTitle}>Name </div>
                        <div className={classes.itemContent}>
                            {props.data.firstName} {props.data.lastName}
                        </div>
                        <div className={classes.itemTitle}>Email </div>
                        <div className={classes.itemContent}>
                            {props.data.email}
                        </div>
                        <div className={classes.itemTitle}>Phone </div>
                        <div className={classes.itemContent}>
                            {props.data.phone}
                        </div>
                        <div className={classes.itemTitle}>Address </div>
                        <div className={classes.itemContent}>
                            {props.data.address}
                        </div>
                        <div className={classes.itemTitle}>Postcode </div>
                        <div className={classes.itemContent}>
                            {props.data.postcode}
                        </div>
                    </div>
                    <div className={classes.section}>
                        <div className={classes.itemTitle}>Type of service</div>
                        <div className={classes.itemContent}>
                            {props.data.serviceType}
                        </div>
                        <div className={classes.itemTitle}>
                            How often do you need our services?
                        </div>
                        <div className={classes.itemContent}>
                            {props.data.frequency}
                        </div>
                        <div className={classes.itemTitle}>
                            Your preferred day for cleaning
                        </div>
                        <div className={classes.itemContent}>
                            {props.data.cleaningDays}
                        </div>
                        <div className={classes.itemTitle}>
                            Your preferred time for cleaning
                        </div>
                        <div className={classes.itemContent}>
                            {props.data.cleaningTimes}
                        </div>
                        <div className={classes.itemTitle}>
                            Start Date & Time
                        </div>
                        <div className={classes.itemContent}>
                            {format(
                                new Date(props.data.startDate),
                                "MMMM d, yyyy"
                            )}&nbsp;
                            {format(new Date(props.data.startTime), "hh:mm a")}
                        </div>
                        <div className={classes.itemTitle}>
                            Your requirements in detail{" "}
                        </div>
                        <div className={classes.itemContent}>
                            {props.data.requirementDesc}
                        </div>
                    </div>

                    <div className={classes.section}>
                        <div className={classes.itemTitle}>
                            How can we access your property?
                        </div>
                        <div className={classes.itemContent}>
                            {props.data.propertyAccess}
                        </div>
                        <div className={classes.itemTitle}>
                            Parking availability
                        </div>
                        <div className={classes.itemContent}>
                            {props.data.parkingAvailability}
                        </div>
                        <div className={classes.itemTitle}>
                            Do you have any pets?
                        </div>
                        <div className={classes.itemContent}>
                            {props.data.pets}
                        </div>
                        <div className={classes.itemTitle}>
                            Further comments
                        </div>
                        <div className={classes.itemContent}>
                            {props.data.comments}
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button
                        style={{
                            backgroundColor: "#009999",
                            color: "white",
                        }}
                        onClick={() => {
                            window.scrollTo(0, 0);
                            props.previousStep();
                        }}
                        fullWidth
                        variant="contained"
                        className={classes.submit}
                    >
                        Previous Step
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button
                        style={{
                            backgroundColor: "#009999",
                            color: "white",
                        }}
                        onClick={() => {
                            window.scrollTo(0, 0);
                            props.submitBooking();
                        }}
                        fullWidth
                        variant="contained"
                        className={classes.submit}
                    >
                        Confirm Booking
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Amplify, { Auth } from "aws-amplify";
import NavBar from "./components/NavBar";
import Home from "./components/Home";
import Contact from "./components/Contact";
import apiConfig from "./config/api-config";
import Footer from "./components/Footer";
import OnlineBooking from "./components/OnlineBooking";
import Pricing from "./components/Pricing";

const awsConfig = {
    Analytics: {
        disabled: true,
    },
    API: {
        endpoints: [
            {
                name: apiConfig.name,
                endpoint: apiConfig.endpoint,
                custom_header: async () => {
                    const session = await Auth.currentSession().catch(
                        (error) => {}
                    );
                    if (session) {
                        return {
                            Authorization: `Bearer ${session
                                .getIdToken()
                                .getJwtToken()}`,
                        };
                    } else {
                        return null;
                    }
                },
            },
        ],
    },
};

Amplify.configure(awsConfig);

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <NavBar></NavBar>
                <Switch>
                    <Route path="/contact" component={Contact} />
                    <Route path="/online-booking" component={OnlineBooking} />
                    <Route path="/pricing" component={Pricing} />
                    <Route exact path="/" component={Home} />
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;

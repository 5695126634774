import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { useState } from "react";
import RoomIcon from "@material-ui/icons/Room";

const AnyReactComponent = ({ lat, lng }: { lat: any; lng: any }) => (
    <RoomIcon style={{ color: "red", fontSize: 40 }} />
);

function LocationMap(props: any) {
    const [center, setCenter] = useState({
        lat: 57.151374488573474,
        lng: -2.103358968245177,
    });
    const [zoom, setZoom] = useState(17);

    return (
        <div style={{ height: props.height, width: "100%" }}>
            <GoogleMapReact
                bootstrapURLKeys={{
                    key: "AIzaSyCvuBljxBerFCEfxExrs9qkqDvZTUyV8J0",
                }}
                defaultCenter={center}
                defaultZoom={props.zoom}
            >
                {/* <AnyReactComponent lat={center.lat} lng={center.lng} /> */}
            </GoogleMapReact>
        </div>
    );
}

export default LocationMap;

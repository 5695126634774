import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import { Button, Fab, Grid, Icon, Toolbar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 30,
    },
    line: {
        display: "inline-block",
        width: "100%",
        margin: 10,
        textAlign: "start",
        alignContent: "flex-end",
    },
}));

export default function Footer() {
    const classes = useStyles();

    return (
        <div
            style={{
                backgroundColor: "#006666",
                color: "white",
                width: "100%",
            }}
        >
            <Grid className={classes.container} container spacing={2}>
                <Grid
                    item
                    xs={12}
                    sm={9}
                    style={{ margin: "auto", textAlign: "start", padding: 40 }}
                >
                    <span style={{ fontSize: 30, display: "block" }}>
                        Aberdeen Cleaning
                    </span>
                    <span style={{ fontSize: 15, display: "block" }}>
                        Opening hours:&nbsp;&nbsp;Monday to Friday 9am to 5pm
                    </span>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <div className={classes.line}>
                        <span style={{ fontSize: 18 }}>Call Us:</span>
                        <br />
                        <span>074 5696 2553</span>
                    </div>
                    <div className={classes.line}>
                        <span style={{ fontSize: 18 }}>Email:</span>
                        <br />
                        <a style={{ color: "white" }} href="mailto:info@aberdeencleaning.com">info@aberdeencleaning.com</a>
                    </div>
                    <div className={classes.line}>
                        <span style={{ fontSize: 18 }}>Tiktok:</span>
                        <br />
                        <a style={{ color: "white" }} href="https://www.tiktok.com/@AbdnCleaning">AbdnCleaning</a>
                    </div>
                    <div className={classes.line}>
                        <span style={{ fontSize: 18 }}>Instagram:</span>
                        <br />
                        <a style={{ color: "white" }} href="https://www.instagram.com/AbdnCleaning">AbdnCleaning</a>
                    </div>
                    <div className={classes.line}>
                        <span style={{ fontSize: 18 }}>Facebook:</span>
                        <br />
                        <a style={{ color: "white" }} href="https://www.facebook.com/AberdeenCleaningCo">AberdeenCleaningCo</a>
                    </div>
                </Grid>
            </Grid>

            <div
                style={{
                    padding: 2,
                    backgroundColor: "#009999",
                }}
            >
                <p style={{ fontSize: 15 }}>
                    2023 Aberdeen Cleaning. All Rights Reserved.
                </p>
            </div>
        </div>
    );
}

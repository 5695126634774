import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { API } from "aws-amplify";
import apiConfig from "../config/api-config";
import { Paper } from "@material-ui/core";
import Notification from "./Notification";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(15),
        marginBottom: theme.spacing(5),
        marginLeft: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
        backgroundColor: "white",
        padding: 0,
    },
    details: {
        marginTop: theme.spacing(15),
        marginBottom: theme.spacing(3),
        display: "flex",
        flexDirection: "column",
        width: "100%",
        textAlign: "left",
        backgroundColor: "white",
    },
    map: {
        height: 300,
    },
    info: {
        marginBottom: 10,
        display: "block",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    title: {
        width: "100%",
        fontSize: 18,
        color: "white",
        backgroundColor: "#009999",
        marginTop: 0,
        padding: "15px 30px 15px 30px",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(2),
        padding: "20px 50px 20px 50px",
    },
    detailsForm: {
        padding: "20px 35px 20px 35px",
    },
    formControl: {
        margin: theme.spacing(0),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function Contact() {
    const classes = useStyles();

    const [firstName, setFirstName] = useState<any>(null);
    const [lastName, setLastName] = useState<any>(null);
    const [email, setEmail] = useState<any>(null);
    const [phone, setPhone] = useState<any>(null);
    const [business, setBusiness] = useState<any>(null);
    const [type, setType] = useState<any>(null);
    const [contactMessage, setContactMessage] = useState<any>(null);
    const [message, setMessage] = useState<any>(null);
    const [messageType, setMessageType] = useState<any>(null);
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        document.title = "Aberdeen Cleaning - Contact";
    }, []);


    const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
        setType(event.target.value);
    };

    const isFormValid = () => {
        return (
            firstName &&
            firstName.length > 0 &&
            firstName &&
            firstName.length > 0 &&
            lastName &&
            lastName.length > 0 &&
            email &&
            email.length > 0 &&
            phone &&
            phone.length > 0 &&
            type &&
            contactMessage &&
            contactMessage.length > 0
        );
    };

    const closeNotification = () => {
        setShowMessage(false);
    };

    const submitEnquiry = async (event: any) => {
        try {
            event.preventDefault();

            const enquiry = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone: phone,
                business: business,
                type: type,
                message: contactMessage,
            };
            const res = await API.post(apiConfig.name, `/${apiConfig.env}/contact`, {
                body: enquiry,
            });
            await setMessage("Message sent successfully!");
            await setMessageType("success");
            await setShowMessage(true);
            setTimeout(() => {
                window.location.replace("/");
            }, 3000);
        } catch (error) {
            console.log(error);
            await setMessage("An error occurred. Please try again!");
            await setMessageType("error");
            await setShowMessage(true);
        }
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <Grid
                container
                spacing={2}
                style={{ width: "90%", margin: "auto", marginBottom: 20 }}
            >
                <Grid item xs={12} sm={4}>
                    <Paper className={classes.details}>
                        <p className={classes.title}>Contact Us</p>
                        <div className={classes.detailsForm}>
                            <span className={classes.info}>
                                Email: info@aberdeencleaning.com
                            </span>
                            <span className={classes.info}>
                                Phone: 074 5696 2553
                            </span>
                        </div>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={5}>
                    <Paper className={classes.paper}>
                        <p className={classes.title}>
                            Fill out details below and we will be in touch soon!
                        </p>
                        <form
                            className={classes.form}
                            noValidate
                            onSubmit={submitEnquiry}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="firstName"
                                        autoComplete="fname"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="First name"
                                        autoFocus
                                        onChange={(e) =>
                                            setFirstName(e.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="lastName"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Last name"
                                        autoComplete="lname"
                                        onChange={(e) =>
                                            setLastName(e.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="email"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Email"
                                        autoComplete="email"
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="phone"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Phone"
                                        onChange={(e) =>
                                            setPhone(e.target.value)
                                        }
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl
                                        variant="outlined"
                                        fullWidth
                                        className={classes.formControl}
                                        required
                                    >
                                        <InputLabel id="demo-simple-select-outlined-label">
                                            Enquiry type
                                        </InputLabel>
                                        <Select
                                            style={{
                                                justifyContent: "flex-start",
                                            }}
                                            labelId="demo-simple-select-outlined-label"
                                            value={type}
                                            onChange={handleChange}
                                            label="Budget allocated"
                                        >
                                            <MenuItem
                                                value={
                                                    "Becoming a new customer"
                                                }
                                            >
                                                Becoming a new customer
                                            </MenuItem>
                                            <MenuItem
                                                value={
                                                    "Request more info about services"
                                                }
                                            >
                                                Request more info about services
                                            </MenuItem>
                                            <MenuItem value={"Working with us"}>
                                                Working with us
                                            </MenuItem>
                                            <MenuItem value={"Other"}>
                                                Other
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel
                                        style={{
                                            marginBottom: 15,
                                            textAlign: "start",
                                        }}
                                    >
                                        &nbsp;&nbsp;&nbsp;Message
                                    </InputLabel>
                                    <TextareaAutosize
                                        minRows={10}
                                        placeholder=" "
                                        style={{ width: "100%" }}
                                        onChange={(e) =>
                                            setContactMessage(e.target.value)
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                style={{
                                    backgroundColor: "#009999",
                                    color: "white",
                                }}
                                type="submit"
                                disabled={!isFormValid()}
                                fullWidth
                                variant="contained"
                                className={classes.submit}
                            >
                                Submit
                            </Button>
                        </form>
                    </Paper>
                    {showMessage && (
                        <Notification
                            open={showMessage}
                            message={message}
                            type={messageType}
                            closeNotification={closeNotification}
                        ></Notification>
                    )}
                </Grid>
            </Grid>
            <Footer></Footer>
        </React.Fragment>
    );
}

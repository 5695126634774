import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

export default function FormProgressBar(props: any) {
    return (
        <ProgressBar
            percent={props.percentage}
            filledBackground="linear-gradient(to right, #fefb72, #009999)"
        >
            <Step transition="scale">
                {({ accomplished }) => (
                    <span style={{marginBottom: 35, marginLeft: 40}}>Details</span>
                )}
            </Step>
            <Step transition="scale">
                {({ accomplished }) => (
                     <span style={{marginBottom: 35}}>Requirements</span>
                )}
            </Step>
            <Step transition="scale">
                {({ accomplished }) => (
                   <span style={{marginBottom: 35}}>Accessibility</span>
                )}
            </Step>
            <Step transition="scale">
                {({ accomplished }) => (
                   <span style={{marginBottom: 35, marginRight: 50}}>Complete</span>
                )}
            </Step>
        </ProgressBar>
    );
}

import React from "react";
import {
    alpha,
    makeStyles,
    Theme,
    createStyles,
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import SearchIcon from "@material-ui/icons/Search";
import MoreIcon from "@material-ui/icons/MoreVert";
import { Button } from "@material-ui/core";
import { useEffect } from "react";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            display: "none",
            // fontWeight: "bold",
            [theme.breakpoints.up("sm")]: {
                display: "block",
            },
            marginLeft: 20,
        },
        search: {
            position: "relative",
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.common.white, 0.15),
            "&:hover": {
                backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 30,
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                marginLeft: theme.spacing(3),
                width: "auto",
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: "100%",
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        inputRoot: {
            color: "inherit",
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create("width"),
            width: "100%",
            [theme.breakpoints.up("md")]: {
                width: "20ch",
            },
        },
        button: {
            color: "white",
            marginInline: 20,
            fontSize: 16,
            textTransform: "none",
        },
        sectionDesktop: {
            display: "none",
            [theme.breakpoints.up("md")]: {
                display: "flex",
            },
        },
        sectionMobile: {
            display: "flex",
            [theme.breakpoints.up("md")]: {
                display: "none",
            },
        },
    })
);

export default function NavBar(props: any) {
    const classes = useStyles();
    const history = useHistory();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
        React.useState<null | HTMLElement>(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const [loggedIn, setLoggedIn] = useState(false);
    const [username, setUsername] = useState("");

    useEffect(() => {
        isLoggedIn();
    });

    const isLoggedIn = async () => {
        await Auth.currentAuthenticatedUser({
            bypassCache: false,
        })
            .then((user) => {
                setLoggedIn(true);
                setUsername(user.attributes.given_name);
            })
            .catch((err) => setLoggedIn(false));
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleScroll = (id: string) => {
        const anchor = document.querySelector(id);

        if (anchor) {
            anchor.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };

    const signOut = async () => {
        await Auth.signOut({ global: true });
        window.location.replace("/");
    };

    const menuId = "primary-search-account-menu";
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={signOut}>Sign out</MenuItem>
        </Menu>
    );

    const mobileMenuId = "primary-search-account-menu-mobile";
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={() => window.location.replace("/")}>
                <p>Home</p>
            </MenuItem>
            <MenuItem onClick={() => window.location.replace("/contact")}>
                <p>Pricing</p>
            </MenuItem>
            <MenuItem onClick={() => window.location.replace("/contact")}>
                <p>Contact</p>
            </MenuItem>
            <MenuItem onClick={() => window.location.replace("/online-booking")}>
                <p>Book Online</p>
            </MenuItem>
        </Menu>
    );

    return (
        <React.Fragment>
            <div
                style={{
                    flex: 1,
                    left: 0,
                    top: 0,
                    width: "100%",
                    zIndex: 9999,
                    backgroundColor: "#009999",
                }}
            >
                <div className={classes.grow}>
                    <AppBar
                        position="static"
                        style={{ background: "transparent" }}
                    >
                        <Toolbar>
                            <Typography
                                className={classes.title}
                                variant="h6"
                                noWrap
                            >
                                Aberdeen Cleaning
                            </Typography>
                            <div className={classes.grow} />
                            <div className={classes.sectionDesktop}>
                                <Button
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={() => {
                                        history.replace("/");
                                    }}
                                    className={classes.button}
                                >
                                    Home
                                </Button>

                                <Button
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={() => {
                                        handleScroll("#services");
                                    }}
                                    className={classes.button}
                                >
                                    What We Do
                                </Button>
                                <Button
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={() => {
                                        history.replace("/contact");
                                    }}
                                    className={classes.button}
                                >
                                    Pricing
                                </Button>
                                <Button
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={() => {
                                        history.replace("/contact");
                                    }}
                                    className={classes.button}
                                >
                                    Contact
                                </Button>
                                <Button
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={() => {
                                        history.replace("/online-booking");
                                    }}
                                    className={classes.button}
                                >
                                    Book Online
                                </Button>
                            </div>
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    placeholder="Search…"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ "aria-label": "search" }}
                                />
                            </div>

                            <div className={classes.sectionMobile}>
                                <IconButton
                                    aria-label="show more"
                                    aria-controls={mobileMenuId}
                                    aria-haspopup="true"
                                    onClick={handleMobileMenuOpen}
                                    color="inherit"
                                >
                                    <MoreIcon />
                                </IconButton>
                            </div>
                        </Toolbar>
                    </AppBar>
                    {renderMobileMenu}
                    {renderMenu}
                </div>
            </div>
        </React.Fragment>
    );
}

import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Fab, Grid, Icon, Toolbar } from "@material-ui/core";
import { Zoom, Fade, Slide } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import React, { useEffect } from "react";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Footer from "./Footer";
import ImageSlider from "./ImageSlider";
import LocationMap from "./LocationMap";
import {
	Receipt,
	People,
	ThumbUp,
	AccessAlarm,
	WatchLater,
	Star,
	Security,
} from "@material-ui/icons";

export default function Home() {
	const classes = useStyles();

	useEffect(() => {
		document.title = "Aberdeen Cleaning";
	}, []);

	return (
		<React.Fragment>
			<div id="back-to-top-anchor" />
			<ImageSlider></ImageSlider>
			<div id="services">
				<p style={{ fontSize: 30, fontWeight: "bold", marginTop: 40 }}>
					What We Do
				</p>
				<Grid className={classes.services} container spacing={2}>
					<Grid item xs={12} sm={4}>
						<Paper elevation={3} className={classes.paper}>
							<img
								alt="devices"
								src={`${process.env.PUBLIC_URL}/assets/images/domestic-cleaning.jpg`}
								style={{ width: "100%" }}
							></img>
							<p className={classes.paperTitle}>
								Regular Domestic Cleaning
							</p>
							<p className={classes.paperDesc}>
								Regular Domestic Cleaning includes cleaning of
								Bedrooms, Bathrooms, Kitchen, Living rooms and
								Halls. Our services are available weekly, daily
								or fortnightly.
							</p>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Paper elevation={3} className={classes.paper}>
							<img
								alt="devices"
								src={`${process.env.PUBLIC_URL}/assets/images/deep-cleaning.jpg`}
								style={{ width: "100%" }}
							></img>
							<p className={classes.paperTitle}>Deep Cleaning</p>
							<p className={classes.paperDesc}>
								If you need your house in the perfect condition
								before selling, moving in or for any other
								reason, we provide thorough cleaning based on
								your requirements.
							</p>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Paper elevation={3} className={classes.paper}>
							<img
								alt="devices"
								src={`${process.env.PUBLIC_URL}/assets/images/office-cleaning.jpg`}
								style={{ width: "100%" }}
							></img>
							<p className={classes.paperTitle}>Commercial Cleaning</p>
							<p className={classes.paperDesc}>
								We ensure your office space and workspace are
								clean, presentable and comfortable. Whether it
								is a small or large commercial business we
								deliver the best cleaning you need!
								{/* Dusting and hoovering, washing clothes or
                                whatever you need our housekeeping staff to do,
                                they will go above and beyond to ensure that
                                your needs are always met. */}
							</p>
						</Paper>
					</Grid>
				</Grid>
				<Grid className={classes.services} container spacing={2}>
					<Grid item xs={12} sm={4}>
						<Paper elevation={3} className={classes.paper}>
							<img
								alt="devices"
								src={`${process.env.PUBLIC_URL}/assets/images/pre-tenancy.jpg`}
								style={{ width: "100%" }}
							></img>
							<p className={classes.paperTitle}>
								Pre Tenancy Cleaning
							</p>
							<p className={classes.paperDesc}>
								We provide cleaning services for getting a property ready for tenants including hovering carpets, 
                                mopping, scrubbing & cleaning mirrors etc.
							</p>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Paper elevation={3} className={classes.paper}>
							<img
								alt="devices"
								src={`${process.env.PUBLIC_URL}/assets/images/end-tenancy.jpg`}
								style={{ width: "100%" }}
							></img>
							<p className={classes.paperTitle}>
								End of Tenancy Cleaning
							</p>
							<p className={classes.paperDesc}>
								End of tenancy cleaning provided to meet the
								requirements of landlords, letting agents and
								tenants.
							</p>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={4}></Grid>
				</Grid>
			</div>

			<div
				style={{
					backgroundColor: "#009999",
					color: "white",
					padding: "10px 100px 20px 100px",
				}}
				id="about-us"
			>
				<p
					style={{
						fontSize: 25,
						fontWeight: "bold",
					}}
				>
					Why Aberdeen Cleaning?
				</p>
				<p style={{ fontSize: 18 }}>
					We are a cleaning company based in Aberdeen, United Kingdom.
					Our well trained staff offer professional service including
					both domestic and commercial cleaning services to our
					customers.
				</p>
			</div>

			<div
				style={{
					padding: "20px 80px 0px 80px",
				}}
				id="about-us"
			>
				<p
					style={{
						fontSize: 25,
						fontWeight: "bold",
						marginBottom: 40,
					}}
				>
					What We Offer
				</p>
				<Grid className={classes.offers} container spacing={2}>
					<Grid item xs={12} sm={4}>
						<Paper elevation={3} className={classes.paper2}>
							<ThumbUp className={classes.icon}></ThumbUp>
							<p className={classes.paperTitle2}>
								Great Customer Service
							</p>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Paper elevation={3} className={classes.paper2}>
							<Receipt className={classes.icon}></Receipt>
							<p className={classes.paperTitle2}>
								Reasonable Pricing
							</p>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Paper elevation={3} className={classes.paper2}>
							<People className={classes.icon}></People>
							<p className={classes.paperTitle2}>
								Experienced Cleaners
							</p>
						</Paper>
					</Grid>
				</Grid>
				<Grid className={classes.offers} container spacing={2}>
					<Grid item xs={12} sm={4}>
						<Paper elevation={3} className={classes.paper2}>
							<WatchLater className={classes.icon}></WatchLater>
							<p className={classes.paperTitle2}>
								Flexible Times & Schedules
							</p>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Paper elevation={3} className={classes.paper2}>
							<Security className={classes.icon}></Security>
							<p className={classes.paperTitle2}>Security</p>
						</Paper>
					</Grid>
					<Grid item xs={12} sm={4}>
						<Paper elevation={3} className={classes.paper2}>
							<Star className={classes.icon}></Star>
							<p className={classes.paperTitle2}>Efficiency</p>
						</Paper>
					</Grid>
				</Grid>
			</div>

			<div
				style={{
					padding: "0px 80px 20px 80px",
				}}
				id="about-us"
			>
				<p
					style={{
						fontSize: 25,
						fontWeight: "bold",
					}}
				>
					Areas We Cover
				</p>
				<p style={{ fontSize: 18 }}>
					We provide our cleaning services to customers across
					Aberdeen. Please contact us to check availability.
				</p>
			</div>

			<LocationMap zoom={13} height={350}></LocationMap>

			<Footer></Footer>

			<ScrollTop>
				<Fab
					color="secondary"
					size="small"
					aria-label="scroll back to top"
				>
					<KeyboardArrowUpIcon />
				</Fab>
			</ScrollTop>
		</React.Fragment>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		services: {
			paddingRight: 30,
			paddingLeft: 30,
			marginBottom: 60,
		},
		offers: {
			paddingRight: 30,
			paddingLeft: 30,
			marginBottom: 30,
		},
		paper: {
			width: "95%",
			height: "100%",
			margin: "auto",
			paddingBottom: 10,
			// paddingTop: 5,
		},
		paperTitle: {
			fontSize: 20,
			fontWeight: "bold",
		},
		paperDesc: {
			marginRight: 40,
			marginLeft: 40,
			textAlign: "left",
		},
		paper2: {
			width: "95%",
			margin: "auto",
			// paddingBottom: 10,
		},
		paperTitle2: {
			// fontWeight: "bold",
			fontSize: 17,
			backgroundColor: "#009999",
			color: "white",
			padding: 10,
		},
		icon: {
			marginTop: 20,
			fontSize: 50,
			color: "#009999",
		},
		scrollButton: {
			position: "fixed",
			bottom: theme.spacing(5),
			right: theme.spacing(2),
		},
	})
);

function ScrollTop(props: any) {
	const { children, window } = props;
	const classes = useStyles();

	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
		disableHysteresis: true,
		threshold: 100,
	});

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		const anchor = (
			(event.target as HTMLDivElement).ownerDocument || document
		).querySelector("#back-to-top-anchor");

		if (anchor) {
			anchor.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	};

	return (
		<Zoom in={trigger}>
			<div
				onClick={handleClick}
				role="presentation"
				className={classes.scrollButton}
			>
				{children}
			</div>
		</Zoom>
	);
}
